import { types } from "./types";
import { types as formTypes } from "../form/types";

const initialState = [];

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case formTypes.initializePromotionForm:
            return initialState;
        case types.updatePromotions:
            const updated = state.filter((x) => payload.every((y) => y.productId !== x.productId));
            return [...updated, ...payload];
        default:
            return state;
    }
};