import React from "react";
import { useParams } from "react-router-dom";
import { carOwners } from "types/operationsEnums";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { api } from "api";
import { CarDocument } from "Operations-NotesAndBenefits";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormSelect, FormSwitch, FormTextField } from "components-ts/forms";
import CarDocumentValidator from "./validator";

const CarForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.notesAndBenefits.useGetCar(id);
    const validatorResolver = useValidator(new CarDocumentValidator());

    const form = useForm<CarDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "car",
                params: { id: data?.registrationNumber ?? id },
            }}
            formPath={Paths.Dictionaries.CarForm}
            listPath={Paths.Dictionaries.CarList}
            initQueryStatus={status}
            submitMutation={api.operations.notesAndBenefits.useCreateOrUpdateCar(id)}
            id={id}
            applyBackendDataOnSuccess={true}
        >
            <FormRow>
                <FormTextField
                    name="registrationNumber"
                    label={t(common.carRegistrationNumber)}
                />
                <FormTextField
                    name="brand"
                    label={t(common.brand)}
                />
                <FormSelect
                    name="owner"
                    label={t(common.owner)}
                    items={carOwners}
                    disableEmpty
                />
                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                    width={200}
                    defaultValue={true}
                />
                <FormSwitch
                    name="isReplacementCar"
                    label={t(common.isReplacementCar)}
                    width={200}
                />
            </FormRow>
        </Form>
    );
};

export default CarForm;
