import { FieldPath, FieldValues } from "react-hook-form";
import moment from "moment";
import { FormControlProps } from "./types";
import { DatePicker } from "components-ts/controls";
import { default as Controller } from "./Controller";

type FormDateProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormControlProps<Date, TFieldValues, TName> & {
    onCustomChange?: (value: Date | undefined, name: string) => void;
    disableFuture?: boolean;
    disablePast?: boolean;
    minDate?: moment.Moment | string;
    maxDate?: moment.Moment | string;
    referenceDate?: moment.Moment | string;
};

const FormDate = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    width,
    useGridItem,
    readOnly = false,
    onCustomChange,
    disableFuture,
    disablePast,
    minDate,
    maxDate,
    warning,
    referenceDate,
}: FormDateProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller<Date | undefined, TFieldValues, TName>
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, onBlur, name, required }, fieldState: { error } }) => {
                return (
                    <DatePicker
                        ref={ref}
                        onChange={(newValue) => {
                            onChange(newValue);
                            if (onCustomChange) {
                                onCustomChange(newValue, name);
                            }
                        }}
                        onBlur={onBlur}
                        value={value ? (moment.isDate(value) ? value : moment(value).toDate()) : undefined}
                        label={label}
                        name={name}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        readOnly={readOnly}
                        minDate={minDate}
                        maxDate={maxDate}
                        warning={warning}
                        required={required}
                        referenceDate={referenceDate}
                    />
                );
            }}
            defaultValue={defaultValue}
            useGridItem={useGridItem}
            width={width}
        />
    );
};

export default FormDate;
