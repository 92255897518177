import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, Typography } from "@mui/material";
import QueryErrorBoundary from "components-ts/suspense/queryErrorBoundary/QueryErrorBoundary";

const Fallback: React.FC<{
    height: number;
    width: number;
}> = ({ height, width }) => {
    const { t } = useTranslation();

    return (
        <Grid
            style={{ height, width: "100%" }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <CircularProgress size={72} />
            </Grid>
            <Grid item>
                <Typography variant="button">{t("loading").toString()}</Typography>
            </Grid>
        </Grid>
    );
};

const SuspenseContainer: React.FC<{
    children: React.ReactNode;
    height?: number;
    width?: number;
}> = ({ children, height = 800, width = 400 }) => {
    return (
        <Suspense
            fallback={
                <Fallback
                    height={height}
                    width={width}
                />
            }
        >
            <QueryErrorBoundary
                height={height}
                width={width}
            >
                {children}
            </QueryErrorBoundary>
        </Suspense>
    );
};

export default SuspenseContainer;
