import { useState } from "react";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { Fetcher, FriscoCustomHeaders, Query } from "api-types";
import { blobFetcher, jsonFetcher } from "./fetchers";
import _ from "lodash";

const SECOND = 1000;
const MINUTE = SECOND * 60;

const useQuery = <TResponse = {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
    queryConfig: Fetcher.Query<TResponse, TQueryParams, TBody>
): Query.UseQueryResult<TResponse, TQueryParams, TBody> => {
    const { queryParams: initialParams, body: initialBody, headers: initialHeaders, url, options, shouldMakeRequest } = queryConfig;

    const [params, setParams] = useState<Query.Params<TQueryParams, TBody>>({
        queryParams: initialParams,
        body: initialBody,
        headers: initialHeaders,
    } as Query.Params<TQueryParams, TBody>);

    const setQueryParams = (newParams: TQueryParams) => {
        setParams({ ...params, queryParams: newParams } as Query.Params<TQueryParams, TBody>);
    };

    const setBodyParams = (newParams: Partial<TBody>) => {
        setParams({ ...params, body: newParams } as Partial<Query.Params<TQueryParams, TBody>>);
    };

    const setHeaderParams = (newParams: FriscoCustomHeaders) => {
        setParams({ ...params, headers: newParams } as Partial<Query.Params<TQueryParams, TBody>>);
    };

    const isFileRequest = <TResponse, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
        query: Fetcher.Query<TResponse, TQueryParams, TBody> | Fetcher.FileRequest<TResponse, TQueryParams, TBody>
    ): query is Fetcher.FileRequest<TResponse, TQueryParams, TBody> => {
        return (query as Fetcher.FileRequest<TResponse, TQueryParams, TBody>).fileName !== undefined;
    };

    const makeRequest =
        shouldMakeRequest !== undefined
            ? typeof shouldMakeRequest === "function"
                ? shouldMakeRequest(params.queryParams || ({} as TQueryParams))
                : shouldMakeRequest
            : true;

    const response = useReactQuery<TResponse, Response, TResponse, unknown[]>({
        queryFn: () => {
            if (isFileRequest(queryConfig)) {
                return blobFetcher(queryConfig, params.queryParams, params.body, params.headers);
            } else {
                const { responseType = "json" } = queryConfig;
                if (responseType == "json") {
                    return jsonFetcher(queryConfig, params.queryParams, params.body, params.headers);
                } else if (responseType === "base64") {
                    return blobFetcher(queryConfig, params.queryParams, params.body);
                }

                //TODO other response type ex. FormData
                throw new Error("Not implemented");
            }
        },
        ...options,
        enabled:
            shouldMakeRequest !== undefined
                ? typeof shouldMakeRequest == "function"
                    ? shouldMakeRequest(params.queryParams || ({} as TQueryParams))
                    : shouldMakeRequest
                : true,
        refetchOnMount: (queryConfig.responseType || "json") === "json" ? makeRequest : false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        queryKey: [
            url,
            ...Object.values(params.queryParams || {}),
            ...Object.values(params.body || {}),
            ...Object.values(params.headers || {}),
        ] as unknown[],
    });

    return {
        ...response,
        setHeaders: setHeaderParams,
        setQueryParams: setQueryParams,
        setBodyParams: setBodyParams,
        params: params,
        headers: params.headers,
        queryParams: params.queryParams as TQueryParams,
        bodyParams: params.body as TBody,
        data: response.data as TResponse,
    } as Query.UseQueryResult<TResponse, TQueryParams, TBody>;
};

/** Use for getting document */
export const useGetQuery = <TResponse extends {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>({
    app,
    url,
    queryParams,
    friscoCustomHeaders,
    silentError = false,
    shouldMakeRequest,
    refetchOnMount = false,
}: Pick<Fetcher.Request<TResponse, TQueryParams, TBody>, "app" | "url" | "queryParams" | "friscoCustomHeaders" | "shouldMakeRequest"> & {
    silentError?: boolean;
    refetchOnMount?: boolean;
}) => {
    return useQuery<TResponse, TQueryParams, TBody>({
        app: app,
        method: "GET",
        url: url,
        queryParams,
        friscoCustomHeaders,
        shouldMakeRequest,
        options: {
            refetchOnMount: refetchOnMount, //enabled
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 5,
            retryDelay: 3 * SECOND,
            staleTime: SECOND,
        },
        silentError,
        responseType: "json",
        contentType: "json",
    });
};

/** Use for getting paged result */
export const useGetPagedQuery = <TResponse extends {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>({
    app,
    url,
    method = "GET",
    queryParams,
    body,
    friscoCustomHeaders,
    silentError = false,
    shouldMakeRequest,
}: Pick<Fetcher.Request<TResponse, TQueryParams, TBody>, "app" | "url" | "queryParams" | "body" | "friscoCustomHeaders" | "shouldMakeRequest"> & {
    method?: "GET" | "POST";
    silentError?: boolean;
}) => {
    return useQuery<TResponse, TQueryParams, TBody>({
        app: app,
        method: method,
        url: url,
        queryParams,
        body,
        shouldMakeRequest,
        friscoCustomHeaders,
        options: {
            refetchOnMount: false, //enabled
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false, //enabled
            staleTime: SECOND,
        },
        silentError,
        responseType: "json",
        contentType: "json",
    });
};

/** Use when result should be cached ex. productData from commerce */
export const useGetCacheQuery = <TResponse extends {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>({
    app,
    url,
    queryParams,
    friscoCustomHeaders,
    silentError = false,
    cacheTime = 5,
    shouldMakeRequest,
}: Pick<Fetcher.Request<TResponse, TQueryParams, TBody>, "app" | "url" | "queryParams" | "friscoCustomHeaders" | "shouldMakeRequest"> & {
    silentError?: boolean;
    /** cache time in minutes */
    cacheTime?: number;
}) => {
    return useQuery<TResponse, TQueryParams, TBody>({
        app: app,
        method: "GET",
        url: url,
        queryParams,
        shouldMakeRequest,
        friscoCustomHeaders,
        options: {
            refetchOnMount: false, //enabled
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false, //enabled
            staleTime: cacheTime * MINUTE,
            gcTime: 2 * cacheTime * MINUTE,
        },
        silentError,
        responseType: "json",
        contentType: "json",
    });
};

/** Use to getting file */
export const useFileQuery = <TResponse extends Fetcher.FileResponse, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>({
    app,
    url,
    fileName,
    shouldMakeRequest,
    queryParams,
    body,
    method = "GET",
    silentError = false,
    downloadFile = true,
}: Pick<Fetcher.Query<TResponse, TQueryParams, TBody>, "app" | "url" | "queryParams" | "body" | "fileName" | "shouldMakeRequest"> & {
    method?: Fetcher.Method;
    silentError?: boolean;
    downloadFile?: boolean;
    fileName: string;
}) => {
    const fileParams = downloadFile
        ? {
              responseType: "file" as Extract<Fetcher.DataType, "file">,
              fileName: fileName,
          }
        : {
              responseType: "base64" as Extract<Fetcher.DataType, "base64">,
              fileName: undefined,
          };

    return useQuery<TResponse, TQueryParams, TBody>({
        app: app,
        method: method,
        url: url,
        body: body,
        queryParams: queryParams,
        shouldMakeRequest,
        silentError,
        contentType: "json",
        ...fileParams,
    });
};
