import { UseMutationResult, useQueryClient, useMutation as useReactMutation } from "@tanstack/react-query";
import { Fetcher } from "api-types";
import { jsonFetcher } from "./fetchers";

const useMutation = <TBody extends Fetcher.Body, TResponse>(
    commandConfig: Fetcher.Command<TResponse, TBody> & { invalidateUrls?: string[] }
): UseMutationResult<TResponse, Response, TBody, unknown> => {
    const queryClient = useQueryClient();
    const mutation = useReactMutation<TResponse, Response, TBody, unknown>({
        mutationKey: [commandConfig.url, {}, {}],
        mutationFn: (body) => jsonFetcher<TResponse, {}, TBody>(commandConfig, undefined, body),
        ...commandConfig.options,
        onSuccess: (data, variables, context) => {
            commandConfig.options?.onSuccess?.(data, variables, context);

            if (commandConfig.invalidateUrls?.length) {
                commandConfig.invalidateUrls.forEach((invalidateUrl) => {
                    queryClient.invalidateQueries({
                        queryKey: [invalidateUrl],
                        exact: false,
                    });
                });
            }
        },
    });

    return mutation as UseMutationResult<TResponse, Response, TBody, unknown>;
};

export const useCreateOrUpdateMutation = <TBody extends Fetcher.Body, TResponse>({
    app,
    url,
    id,
    silentError = false,
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url"> & {
    id?: string;
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: !id ? "POST" : "PUT",
        options: {
            mutationKey: [app, url],
        },
        silentError,
        contentType: "json",
    });
};

export const useCreateMutation = <TBody extends Fetcher.Body, TResponse>({
    app,
    url,
    silentError = false,
    contentType = "json",
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url" | "contentType"> & {
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: "POST",
        options: {
            mutationKey: [app, url],
        },
        silentError,
        contentType,
    });
};

export const useUpdateMutation = <TBody extends Fetcher.Body, TResponse>({
    app,
    url,
    silentError = false,
    contentType = "json",
    invalidateUrls = [],
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url" | "contentType"> & {
    silentError?: boolean;
    invalidateUrls?: string[];
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: "PUT",
        options: {
            mutationKey: [app, url],
        },
        silentError,
        contentType,
        invalidateUrls,
    });
};

export const useDeleteMutation = <TBody extends Fetcher.Body = {}, TResponse = void>({
    app,
    url,
    silentError = false,
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url"> & {
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: "DELETE",
        silentError,
        options: {
            mutationKey: [app, url],
        },
        contentType: "json",
    });
};

export const useFileMutation = <TBody extends Fetcher.Body = {}>({
    app,
    url,
    silentError = false,
}: Pick<Fetcher.Command<undefined, TBody>, "app" | "url"> & {
    silentError?: boolean;
}) => {
    return useMutation<TBody, undefined>({
        app: app,
        url: url,
        method: "POST",
        silentError,
        options: {
            mutationKey: [app, url],
        },
    });
};
