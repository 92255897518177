import { useCreateMutation, useFileQuery, useGetPagedQuery, useGetQuery } from "api/hooks";
import { ComplaintBatchDocument, ImportComplaintBatch, ReadComplaintBatchResult } from "Operations-Complaints";
import { PagedCriteria, PagedResult } from "Shared";

const queryKey = "api/complaint-batches";

export const useFindComplaintBatches = (initialParams: PagedCriteria) => {
    return useGetPagedQuery<PagedResult<ComplaintBatchDocument>, PagedCriteria>({
        app: "operations",
        url: queryKey,
        queryParams: initialParams,
    });
};

export const useGetComplaintBatch = (id?: string) => {
    return useGetQuery<ComplaintBatchDocument>({
        app: "operations",
        url: `${queryKey}/${id === undefined ? "" : id}`,
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetComplaintBatchFile = (id?: string) => {
    return useFileQuery({
        app: "operations",
        url: `${queryKey}/${id}/file`,
        shouldMakeRequest: Boolean(id),
        fileName: "reklamacje",
    });
};

export const useImportComplaintBatch = () => {
    return useCreateMutation<ImportComplaintBatch, ReadComplaintBatchResult>({
        app: "operations",
        url: `${queryKey}/import`,
        contentType: "formData",
    });
};

export const useValidateComplaintBatch = () => {
    return useCreateMutation<Omit<ImportComplaintBatch, "reasonCode">, ReadComplaintBatchResult>({
        app: "operations",
        url: `${queryKey}/validate`,
        contentType: "formData",
    });
};
