import { default as SuneditorReact } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    image,
    lineHeight,
    link,
    list,
    paragraphStyle,
    table,
    textStyle,
    video,
} from "suneditor/src/plugins";
import React, { forwardRef } from "react";
import { InputLabel } from "@mui/material";
import { RichTextFieldProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";

const RichTextField: React.FC<RichTextFieldProps> = forwardRef(({ label, error, readOnly, defaultValue, onChange, warning }, ref) => {
    return (
        <>
            <InputLabel id={`${label}-label`}>{label}</InputLabel>

            <SuneditorReact
                lang="pl"
                onChange={(newValue) => {
                    if (onChange) {
                        if (newValue === "" || newValue.replace(" ", "") === "<p><br></p>" || newValue.replace(" ", "") === "<p><br/></p>") {
                            onChange(undefined);
                        } else {
                            onChange(newValue);
                        }
                    }
                }}
                autoFocus={false}
                setOptions={{
                    plugins: [
                        align,
                        font,
                        fontColor,
                        fontSize,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        lineHeight,
                        list,
                        paragraphStyle,
                        table,
                        textStyle,
                        link,
                        image,
                        video,
                    ],
                    mode: readOnly ? "balloon" : "classic",
                    buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                        ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        "/", // Line break
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link", "image", "video"],
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview", "print"],
                    ],
                    imageFileInput: false,
                }}
                disable={readOnly}
                defaultValue={defaultValue}
            />

            <FormHelperText
                error={error}
                warning={warning}
            />
        </>
    );
});

export default RichTextField;
