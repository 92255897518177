import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useDeleteMutation, useFileQuery, useGetPagedQuery, useGetQuery, useUpdateMutation } from "api/hooks";
import {
    ChargesRegisterDocument,
    ChargesRegisterHasRegisterQuery,
    ChargesRegisterRequest,
    ChargesRegisterResponse,
} from "Operations-NotesAndBenefits";

const url = (id?: string) => generatePath(`api/charges-register/:id?`, { id: id ? id : null });

export const useFindChargesRegisters = (initialParams: ChargesRegisterRequest) => {
    return useGetPagedQuery<ChargesRegisterResponse, ChargesRegisterRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetChargesRegister = (id?: string) => {
    return useGetQuery<ChargesRegisterDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useIsAnyChargesRegisterOpen = (recipientId?: string, params?: ChargesRegisterHasRegisterQuery) => {
    return useGetQuery<string, ChargesRegisterHasRegisterQuery>({
        app: "operations",
        url: `api/recipients/${recipientId}/has-register`,
        queryParams: params,
        shouldMakeRequest: (params) => Boolean(recipientId) && Boolean(params?.year) && Boolean(params?.month) && Boolean(params?.employmentType),
    });
};

export const useCreateOrUpdateChargesRegister = (id?: string) => {
    return useCreateOrUpdateMutation<ChargesRegisterDocument, ChargesRegisterDocument>({
        app: "operations",
        url: url(id),
        id: id,
    });
};

export const useCloseChargesRegister = (id?: string) => {
    return useUpdateMutation<Array<string>, ChargesRegisterDocument>({
        app: "operations",
        url: url(id) + "/close",
    });
};

export const useDeleteChargesRegister = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url(id),
    });
};

export const useExportChargesRegisters = (
    initialParams: Omit<ChargesRegisterRequest, "pageIndex" | "pageSize"> | { ids: string[] },
    isExporting: boolean
) => {
    return useFileQuery({
        app: "operations",
        url: url() + "/export",
        fileName: "export danych.xlsx",
        queryParams: initialParams,
        shouldMakeRequest: isExporting,
    });
};

export const useExportChargesRegistersHeaders = (
    initialParams: Omit<ChargesRegisterRequest, "pageIndex" | "pageSize"> | { ids: string[] },
    isExporting: boolean
) => {
    return useFileQuery({
        app: "operations",
        url: url() + "/export-headers",
        fileName: "export nagłówków.xlsx",
        queryParams: initialParams,
        shouldMakeRequest: isExporting,
    });
};

export const useCloseChargesRegisters = () => {
    return useUpdateMutation<Omit<ChargesRegisterRequest, "pageIndex" | "pageSize"> | { ids: string[] }, undefined>({
        app: "operations",
        url: url() + "/close",
    });
};
