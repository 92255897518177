import { generatePath } from "react-router-dom";
import {
    PromotionCreator,
    PromotionCreatorListResponse,
    PromotionCreatorPageRequest,
    PromotionCreatorSearchParameters,
    PromotionCreatorSearchResult,
} from "Operations";
import { useCreateOrUpdateMutation, useGetCacheQuery, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (promotionCreatorId?: string) =>
    generatePath(`api/bridge/promotion-creator/:promotionCreatorId?`, { promotionCreatorId: promotionCreatorId ? promotionCreatorId : null });

export const useFindPromotionsCreators = (initialParams: PromotionCreatorPageRequest) => {
    return useGetQuery<PromotionCreatorListResponse, PromotionCreatorPageRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetPromotionCreator = (promotionCreatorId?: string) => {
    return useGetQuery<PromotionCreator>({
        app: "operations",
        url: url(promotionCreatorId),
        shouldMakeRequest: Boolean(promotionCreatorId),
        refetchOnMount: true,
    });
};

export const useCreateOrUpdatePromotionCreator = (id?: string) => {
    return useCreateOrUpdateMutation<PromotionCreator, PromotionCreator>({
        app: "operations",
        url: url(id),
        id: id,
    });
};

export const useChangeProductPromotionPropositionStatus = (id?: string) => {
    return useCreateOrUpdateMutation<PromotionCreator, PromotionCreator>({
        app: "operations",
        url: url(id) + "/finish",
        id: id,
    });
};

export const useSearchPromotionPropositions = (
    params: PromotionCreatorSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<PromotionCreatorSearchResult, PromotionCreatorSearchParameters>({
        app: "operations",
        url: url() + "/search",
        queryParams: params,
    });
};

export const useGetSold30DaysTotal = (params?: { productId?: string; warehouse?: string }) => {
    return useGetCacheQuery<number>({
        app: "operations",
        url: url() + "/sold-data",
        shouldMakeRequest: Boolean(params?.productId),
        queryParams: params,
        silentError: true,
        cacheTime: 60,
    });
};
