import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { makeStyles } from "tss-react/mui";
import { formatTypes } from "utils/formating/index.js";
import { useTranslation } from "react-i18next";
import { TableCell } from "@mui/material";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto",
    },
    cell: {
        "&:last-child": {
            paddingRight: theme.spacing(2),
        },
        "whiteSpace": "pre-line",
    },
    numeric: {
        whiteSpace: "nowrap",
        textOverflow: "clip",
    },
}));

const useTableStyles = makeStyles()((theme) => tableStyle(theme));
const useTasksStyles = makeStyles()(tasksStyle);

const numericTypes = [formatTypes.currency, formatTypes.numeric, formatTypes.grammage, formatTypes.percent];

const rightAlignTypes = numericTypes.map((x) => [x, "right"]);

const centerAlignTypes = [formatTypes.infoIcon, formatTypes.boolean].map((x) => [x, "center"]);

const alignMap = new Map([...rightAlignTypes, ...centerAlignTypes]);

const CellFab = ({ value, tooltip }) => {
    const { t } = useTranslation();
    var fab = (
        <Fab
            size="small"
            variant="circular"
            disableRipple
            style={{
                backgroundColor: value === t("true") ? "green" : "red",
                margin: "auto",
                display: "block",
                opacity: "0.7",
                boxShadow: "2px 2px 1px grey",
                height: "36px",
                width: "36px",
            }}
        >
            <></>
        </Fab>
    );

    if (tooltip) {
        return (
            <Tooltip
                title={t(value == "Tak" ? tooltip.true : tooltip.false)}
                placement="right-start"
                arrow
            >
                {fab}
            </Tooltip>
        );
    } else {
        return fab;
    }
};

const Cell = ({ value, children, colSpan, className, format, color, tooltip, showZero, isHeader = false, style = {} }) => {
    const { classes } = useStyles();
    const { classes: tableClasses } = useTableStyles();
    const { classes: tasksClasses } = useTasksStyles();

    let align = "left";
    if (format) {
        const mapValue = alignMap.get(format);
        if (mapValue) {
            align = mapValue;
        }
    }

    const isNumeric = numericTypes.some((x) => x === format);
    const cellValue =
        value || (showZero && value === 0) ? (
            color ? (
                <CellFab
                    value={value}
                    tooltip={tooltip}
                />
            ) : (
                value
            )
        ) : (
            children
        );

    return (
        <TableCell
            align={align}
            style={color ? { minWidth: "130px" } : {}}
            sx={style}
            className={classnames(tasksClasses.tableCell, className, classes.cell)}
            colSpan={colSpan}
        >
            {isNumeric && !isHeader ? <span className={classes.numeric}>{cellValue}</span> : cellValue}
        </TableCell>
    );
};

export default Cell;
