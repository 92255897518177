import { Button } from "components/Button";
import { FormField } from "components/Form";
import { actions as productActions } from "store/cache/products/actions";
import { actions as promotionActions } from "store/offers/promotions/storage/actions";
import { promotionsStorageSelector } from "store/offers/selectors";
import { useCopyRouterState, useMappedDispatch } from "utils/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert } from "@mui/lab";
import { Grid, Link } from "@mui/material";
import { common } from "translations";
import { Paths } from "routing-ts/ManagerPaths";
import ProductItem from "./PromotionPredicateFieldsComponents/ProductItem";
import { productsSelector } from "./PromotionPredicateFieldsComponents/selectors";
import * as XLSX from "xlsx";

const isValidProductId = (v) => Boolean(Number(v)) && v?.length <= 6;

const ProductListField = ({ exportProducts = false, showPromotionExistAlert = false, selectedProducts = [], ...props }) => {
    const { t } = useTranslation("common");
    const { getProducts } = useMappedDispatch(productActions);
    const { getPromotions } = useMappedDispatch(promotionActions);
    const products = useSelector(productsSelector) || [];
    const productPromotions = useSelector(promotionsStorageSelector) || [];
    const [newProducts, setNewProducts] = useState([]);
    const { id } = useCopyRouterState();
    const productsWithPromotions = !showPromotionExistAlert
        ? undefined
        : newProducts
              .map((sp) => productPromotions?.find((p) => p.promotions?.filter((p) => p.id != id).length > 0 && sp == p.productId))
              .filter((sp) => !!sp);

    useEffect(() => {
        if (showPromotionExistAlert && newProducts && newProducts.length) {
            getPromotions(newProducts, productPromotions);
        }
    }, [productPromotions, newProducts, showPromotionExistAlert]);

    const createExcelFile = () => {
        const workSheet = XLSX.utils.book_new();
        const data = selectedProducts.map((o) => {
            return { id: o };
        });

        XLSX.utils.sheet_add_json(workSheet, data, {
            origin: "A1",
            skipHeader: true,
        });
        const workBook = {
            Sheets: { produkty: workSheet },
            SheetNames: ["produkty"],
        };
        const excelBuffer = XLSX.write(workBook, {
            type: "array",
            cellStyles: true,
        });
        const finalData = new Blob([excelBuffer]);
        saveAs(finalData, "produkty.xlsx");
    };

    return (
        <>
            {productsWithPromotions?.length > 0 && (
                <Alert severity="warning">
                    {t(common.productsOnPromotion)}
                    {productsWithPromotions.map((p) => {
                        const product = products.find((product) => product.productId == p.productId)?.product;
                        return (
                            <Grid
                                item
                                key={`${p.productId}-promo`}
                            >
                                {`(${p.productId}) ${product?.brand || ""} - ${product?.name || ""} - `}
                                {p?.promotions
                                    ?.filter((p) => p.id != id)
                                    .map((promo, index, arr) => (
                                        <span key={`${p.productId}-${promo.id}`}>
                                            <Link
                                                target="_blank"
                                                underline="none"
                                                rel="noopener"
                                                href={`${Paths.GeneratePath(Paths.Offers.PromotionForm, { id: promo.id })}`}
                                                key={`${p.productId}-${promo.id}-link`}
                                            >
                                                {promo.name}
                                            </Link>
                                            {index < arr.length - 1 && ", "}
                                        </span>
                                    ))}
                            </Grid>
                        );
                    })}
                </Alert>
            )}
            {exportProducts && (
                <Button
                    color="primary"
                    onClick={() => {
                        createExcelFile();
                    }}
                    disabled={selectedProducts.length < 1}
                >
                    {t(common.exportProducts)}
                </Button>
            )}
            <FormField
                stretch
                {...props}
                name="products"
                label={t(common.products)}
                type="list"
                useList={false}
                itemRenderer={(v, handleRemove, innerProps) => {
                    return (
                        <ProductItem
                            key={v}
                            v={v}
                            handleRemove={handleRemove}
                            products={products}
                            readOnly={innerProps.readOnly}
                        />
                    );
                }}
                onAdd={(productIds) => {
                    setNewProducts((prevProducts) => {
                        const uniqueProducts = new Set([...prevProducts, ...productIds]);
                        return Array.from(uniqueProducts);
                    });
                    getProducts(productIds);
                }}
                additionalValueCheck={isValidProductId}
            />
        </>
    );
};

export default ProductListField;
