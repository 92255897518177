import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role, SupportDepartment } from "../roles";

const RetentionManagers = React.lazy(() =>
    import("views-ts/imports").then((module) => ({
        default: module.RetentionManagers,
    }))
);

const UsersBalanceDebts = React.lazy(() =>
    import("views-ts/imports").then((module) => ({
        default: module.UsersBalanceDebts,
    }))
);

const Complaints = React.lazy(() =>
    import("views-ts/imports").then((module) => ({
        default: module.Complaints,
    }))
);

const roles: Role[] = ["BusinessSupportImporter", ...SupportDepartment.GetRoles()];

export const Imports: PrivateRoute[] = [
    new PrivateRoute("/imports", roles, undefined, "imports", undefined, [
        new PrivateRoute(Paths.Imports.RetentionManagers, roles, RetentionManagers, "retentionManagers", "person"),
        new PrivateRoute(Paths.Imports.UsersBalanceDebts, roles, UsersBalanceDebts, "userBalanceDebts", "credit_score"),
        new PrivateRoute(Paths.Imports.Complaints, SupportDepartment.GetRoles(), Complaints, "complaints", "production_quantity_limits"),
    ]),
];
