import { FormField } from "components/Form";
import { getPromotion } from "store/vendors/containers/actions";
import { containersSelector } from "store/vendors/containers/selectors";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useAppLocation, useChange } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chip, Grid } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../validators";

const guidRE = /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/i;

const isEmpty = (obj) => Object.keys(obj).length === 0;

const parseId = (idOrLink) => {
    const match = guidRE.exec(idOrLink);
    return match ? match[0].toLowerCase() : null;
};

const PromotionItem = ({ promotion, promotionKey, handleRemove, handleClick, isLoading }) => {
    const { t } = useTranslation(["vendor", "common"]);

    return (
        <Chip
            style={{ marginRight: 10, marginTop: 10 }}
            title={isLoading ? `${t(`common:${common.Loading}`)}...` : isEmpty(promotion) ? t(vendor.noPromotion) : t(vendor.goToPromotion)}
            size="medium"
            label={
                isLoading
                    ? `${t(`common:${common.Loading}`)}...`
                    : isEmpty(promotion)
                    ? promotionKey
                    : `${promotion.name} (${promotion.campaignName})`
            }
            color={isEmpty(promotion) && !isLoading ? "warning" : "default"}
            deleteIcon={
                <span
                    title={t(`common:${common.delete}`)}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <CancelIcon />
                </span>
            }
            onDelete={handleRemove}
            onClick={isLoading ? () => {} : handleClick}
        />
    );
};

const RefundationPromotionRestriction = ({ form, useDiff, readOnly, promotionIds = [], isRestrictedToPromotion = false, isAfterDateTo }) => {
    const { promotions } = useSelector(containersSelector);
    const dispatch = useDispatch();
    const change = useChange(form);
    const isValidIds = Array.isArray(promotionIds) ? promotionIds.every((id) => id && parseId(id) === id) : true;
    const appLocation = useAppLocation();
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation("vendor");

    const onIdsOrLinksChanged = (idsOrLinks = []) => {
        if (idsOrLinks.length === 0) {
            return;
        }
        const newIds = Array.from(
            new Set(
                idsOrLinks
                    .map((idOrLink) => {
                        const id = parseId(idOrLink);
                        return id;
                    })
                    .filter(Boolean)
            )
        );
        if (JSON.stringify(newIds) !== JSON.stringify(promotionIds)) {
            change("promotionIds", newIds);
        }
    };

    useEffect(() => {
        if (Array.isArray(promotionIds)) {
            onIdsOrLinksChanged(promotionIds);
        }
    }, [promotionIds]);

    useEffect(() => {
        if (isValidIds) {
            const fetchPromises = [];
            if (Array.isArray(promotionIds)) {
                promotionIds.forEach((id) => {
                    if (!promotions[id]) {
                        fetchPromises.push(dispatch(getPromotion(id)));
                    }
                });
            } else {
                (promotionIds[promotionIds.state] || []).map((id) => {
                    if (!promotions[id.current]) {
                        fetchPromises.push(dispatch(getPromotion(id.current)));
                    }
                });
            }
            Promise.all(fetchPromises).then(() => setIsLoading(false));
        }
    }, [promotionIds, dispatch, isValidIds]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <FormField
                name="isRestrictedToPromotion"
                label={t(vendor.isRestrictedToPromotion)}
                type="boolean"
                useDiff={useDiff}
                readOnly={readOnly}
            />

            {isRestrictedToPromotion === true ||
            (useDiff && !(isRestrictedToPromotion?.proposal === false && isRestrictedToPromotion?.current === false)) ? (
                <FormField
                    style={{ width: "100%" }}
                    label={t(vendor.containerIdsOrLinks)}
                    type="list"
                    name="promotionIds"
                    useList={false}
                    itemRenderer={(v, handleRemove) => {
                        const promotionsArray = promotions && typeof promotions === "object" ? Object.values(promotions) : [];
                        const promotion = firstOrEmpty(promotionsArray, (promotion) => promotion?.id === v);
                        return (
                            <PromotionItem
                                key={v}
                                asChip
                                handleClick={() => window.open(`${appLocation}/offers/promotions/form/${v}`)}
                                handleRemove={readOnly ? undefined : () => handleRemove(v)}
                                promotion={promotion}
                                promotionKey={v}
                                isLoading={isLoading}
                            />
                        );
                    }}
                    readOnly={readOnly}
                    useDiff={useDiff}
                    validate={isAfterDateTo ? validators.required : undefined}
                />
            ) : null}
        </Grid>
    );
};

export default withFormName(RefundationPromotionRestriction);
