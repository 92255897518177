import { RSAA } from "redux-api-middleware";
import { combineIntoQueryParams } from "utils/objectExtensions";
import moment from "moment";
import { types } from "./types";

const getPromotions = (productIds, promotions) => async (dispatch) => {
    if (productIds && productIds?.length > 0) {
        productIds.forEach((id) => {
            const isInStorage = promotions.some((p) => p.productId == id);
            if (!isInStorage) {
                dispatch(
                    getPromotionsForSingleProduct({
                        productId: id,
                    })
                );
            }
        });
    }
};

const getPromotionsForSingleProduct =
    ({ productId }) =>
    async (dispatch) => {
        if (productId) {
            const params = {
                includeFacets: true,
                isActive: true,
                validAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
                pageIndex: 1,
                pageSize: 250,
                tag: "IsActive",
            };
            const endpoint = `api/v1/offer/products/promotions/query`;
            const queryParams = combineIntoQueryParams({
                ...params,
            });

            const { payload, error } = await dispatch({
                [RSAA]: {
                    endpoint: `${endpoint}?${queryParams}`,
                    headers: { "Content-Type": "application/json" },
                    method: "GET",
                    types: [
                        {
                            type: types.getPromotions.request,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                        {
                            type: types.getPromotions.success,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                        {
                            type: types.getPromotions.failure,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                    ],
                    body: "",
                },
            });
            let items = [];
            if (!error) {
                items = payload.items
                    ?.filter((i) => i?.definition?.pools?.some((p) => p?.includeProducts.products?.some((x) => x == productId)))
                    .map((i) => ({
                        productId: productId,
                        id: i.id,
                        name: i.name,
                    }));
            }
            dispatch({
                type: types.updatePromotions,
                payload: [
                    {
                        productId: productId,
                        promotions: items,
                    },
                ],
            });
        }
    };

export const actions = {
    getPromotions,
};
