import { useRef, useState } from "react";
import { Table as MuiTable, TableContainer, useTheme } from "@mui/material";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TablePagination from "./TablePagination";
import { TableItem, TableProps } from "./TableTypes";

const Table = <T extends TableItem>({
    items,
    columns,
    pagination,
    rowHeight = 50,
    loadable,
    handlers,
    initialFilters,
}: TableProps<T>): JSX.Element => {
    const theme = useTheme();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const [isSelectedAll, setIsSelectAll] = useState<boolean>(false);
    const onSelectAllChange = (newValue: boolean | undefined) => {
        if (handlers?.onSelectAllChange) {
            handlers?.onSelectAllChange(newValue);
        }
        setIsSelectAll(newValue || false);
    };
    return (
        <>
            <TableContainer ref={tableContainerRef}>
                <MuiTable
                    stickyHeader
                    size="small"
                    padding="normal"
                    cellPadding={theme.spacing(0)}
                    ref={tableRef}
                >
                    <TableHead
                        columns={columns}
                        handlers={{
                            ...handlers,
                            onFilterChange: (property, value) => {
                                handlers?.onFilterChange && handlers?.onFilterChange(property, value);
                                onSelectAllChange(false);
                            },
                            onFilterClear: () => {
                                handlers?.onFilterClear && handlers?.onFilterClear();
                                onSelectAllChange(false);
                            },
                            onSelectAllChange: onSelectAllChange,
                        }}
                        initialFilters={initialFilters}
                        tableContainerRef={tableContainerRef}
                        tableRef={tableRef}
                        isSelectedAll={isSelectedAll}
                    />
                    <TableBody
                        rowHeight={rowHeight}
                        columns={columns}
                        items={items}
                        loadable={loadable}
                        handlers={{ ...handlers, onSelectAllChange: onSelectAllChange }}
                    />
                </MuiTable>
            </TableContainer>
            {pagination && (
                <TablePagination
                    {...pagination}
                    onPageChange={(val) => {
                        pagination.onPageChange(val);
                        onSelectAllChange(false);
                    }}
                    onPageSizeChange={(val) => {
                        pagination.onPageSizeChange(val);
                        onSelectAllChange(false);
                    }}
                />
            )}
        </>
    );
};

export default Table;
