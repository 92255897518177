import { CarOwner } from "Operations-NotesAndBenefits";
import { PricePoint, ProductClassification } from "Operations-Products";

export const productClassifications: Array<ProductClassification> = ["War", "Top", "Normal", "Tail", "Seasonal"];

export const productClassificationsSelectItems = productClassifications.map((x) => ({
    value: x,
    name: x,
}));

export const pricePoints: Array<PricePoint> = ["Economy", "Mainstream", "Premium"];
export const pricePointsSelectItems = pricePoints.map((x) => ({
    value: x,
    name: x,
}));

export const carOwners: Array<CarOwner> = ["Frisco", "Fraikin", "TruckCare"];
