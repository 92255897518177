import { Autocomplete } from "components/Controls/index.js";
import VirtualizedListbox from "components/VirtualizedListbox/VirtualizedListbox";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { formatTypes, timePrecisonFormats } from "utils/formating/index.js";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { Autocomplete as MUIAutoComplete } from "@mui/lab";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Cell from "./Cell";
import classnames from "classnames";
import _ from "lodash";

const OpenPickerWhiteIcon = styled(EventIcon)({
    color: "#fff",
});
const inputRootStyle = {
    "input": {
        color: "#fff",
    },
    "& label": {
        color: "#fff",
    },
    "& label.Mui-focused": {
        color: "#fff",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white",
    },
    "& .MuiInput-underline:hover:not($disabled):not($.Mui-focused):not($error):before": {
        borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
        borderBottomColor: "white",
    },
};

const styles = makeStyles()((theme) => ({
    borderTopLeft: {
        borderRadius: "7px 0px 0px 0px",
    },
    borderTopRight: {
        borderRadius: "0px 7px 0px 0px",
    },
    cell: {
        textTransform: "uppercase",
        backgroundColor: theme.palette.primary.main,
        color: `#fff`,
    },
    text: {
        color: "#fff",
    },
    label: {
        fontSize: "inherit",
        fontWeight: "inherit",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
        color: "#fff",
    },
    filterText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
        color: "#fff",
    },
    inputRoot: inputRootStyle,
}));

const renderTextField = ({ label, input, ...custom }) => (
    <TextField
        variant="standard"
        label={label}
        title={label}
        fullWidth
        classes={{
            root: custom.classes.inputRoot,
        }}
        InputProps={{
            className: custom.classes.text,
        }}
        InputLabelProps={{
            classes: { root: custom.classes.label },
            style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
                color: "#fff",
            },
        }}
        onKeyUp={custom.handleFilterKeyUp}
        {...input}
    />
);

const renderDatePicker = (props) => {
    const { label, input, shortColumn, defaultValue, classes } = props;
    let value = input.value ? input.value : defaultValue ? defaultValue : null;
    const picker = (
        <DatePicker
            slotProps={{
                textField: {
                    variant: "standard",
                },
            }}
            slots={{
                openPickerIcon: OpenPickerWhiteIcon,
            }}
            label={label}
            title={label}
            value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
            defaultChecked={false}
            format={timePrecisonFormats.days}
            type={"text"}
            sx={inputRootStyle}
            classes={{
                root: classes.inputRoot,
            }}
            InputProps={{
                className: classes.text,
            }}
            InputLabelProps={{
                style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "#fff",
                },
            }}
            onChange={(event) => {
                value = event?.format();
                input.onChange(event?.format());
            }}
            ampm="false"
        />
    );

    return (
        <FormControl
            fullWidth={true}
            classes={{
                root: classes.inputRoot,
            }}
        >
            {shortColumn ? (
                <Tooltip
                    title={label}
                    aria-label={label}
                    placement="right-start"
                >
                    {picker}
                </Tooltip>
            ) : (
                picker
            )}
        </FormControl>
    );
};

const renderSelect = (props) => {
    const { label, input, shortColumn, multiple, defaultValue, ...custom } = props;
    const findName = (value) => {
        const find = (val) =>
            (
                custom.items.find((x) => {
                    if (_.isArray(val)) {
                        if (_.isArray(x.value)) {
                            return val.some((z) => x.value.some((y) => y === z));
                        } else {
                            return val.some((y) => y === x.value);
                        }
                    } else {
                        if (_.isArray(x.value)) {
                            return x.value.some((y) => y === val);
                        } else {
                            return x.value === val;
                        }
                    }
                }) || { name: "" }
            ).name;

        if (!custom.multiple) return find(value);

        return value.map((v) => find(v)).join(", ");
    };
    let value = input.value;
    if (!value || value === false || value === 0) {
        if (multiple) {
            if (defaultValue) {
                value = _.isArray(defaultValue) ? defaultValue : [defaultValue];
            } else {
                value = [];
            }
        } else {
            if (defaultValue) {
                value = _.isArray(defaultValue) ? defaultValue[0] : defaultValue;
            } else {
                value = "";
            }
        }
    }

    const select = (
        <Select
            variant="standard"
            MenuProps={{
                style: {
                    maxHeight: 400,
                },
            }}
            multiple={multiple}
            value={value}
            onOpen={custom.onOpen}
            onClick={custom.onClick}
            onChange={(event) => {
                return custom.onChange ? custom.onChange(event) : input.onChange(event.target.value);
            }}
            onClose={custom.onClose}
            open={custom.open}
            disabled={custom.disabled || false}
            renderValue={(value) => findName(value) || value}
            classes={{
                icon: custom.classes.text,
                root: custom.classes.text,
            }}
            inputProps={{
                readOnly: custom.readOnly || custom.readonly || false,
            }}
            style={{
                minWidth: shortColumn ? "5vw" : undefined,
            }}
        >
            <MenuItem value="">
                <em>(brak)</em>
            </MenuItem>
            {custom.items.map((x, index) => {
                const name = x.polishName || x.englishName || x.name;
                return (
                    <MenuItem
                        disabled={x.disabled}
                        key={index}
                        value={x.value}
                    >
                        <ListItemText>{name}</ListItemText>
                    </MenuItem>
                );
            })}
        </Select>
    );

    return (
        <FormControl
            fullWidth={true}
            classes={{
                root: custom.classes.inputRoot,
            }}
        >
            <InputLabel classes={{ root: custom.classes.label }}>{label}</InputLabel>
            {shortColumn ? (
                <Tooltip
                    title={label}
                    aria-label={label}
                    placement="right-start"
                >
                    {select}
                </Tooltip>
            ) : (
                select
            )}
        </FormControl>
    );
};

function stripDiacritics(string) {
    return typeof string.normalize !== "undefined" ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : string;
}

function createFilterEachWordFromStart(config = {}) {
    const { ignoreAccents = true, ignoreCase = true, limit, matchFrom = "any", stringify, trim = false } = config;

    return (options, { inputValue, getOptionLabel }) => {
        let input = trim ? inputValue.trim() : inputValue;
        if (ignoreCase) {
            input = input.toLowerCase();
        }
        if (ignoreAccents) {
            input = stripDiacritics(input);
        }

        const filteredOptions = options.filter((option) => {
            let candidate = (stringify || getOptionLabel)(option);
            if (ignoreCase) {
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                candidate = stripDiacritics(candidate);
            }

            switch (matchFrom) {
                case "start":
                    return candidate.indexOf(input) === 0;
                case "eachWordStart": {
                    const index = candidate.indexOf(input);
                    return index === 0 || candidate[index - 1] === " ";
                }
                default:
                    return candidate.indexOf(input) > -1;
            }
        });

        return typeof limit === "number" ? filteredOptions.slice(0, limit) : filteredOptions;
    };
}

const renderAutocomplete = ({
    label,
    input,
    items,
    disableCloseOnSelect,
    multiple,
    freeSolo,
    getOptionLabel,
    onCustomChange,
    filterOptions,
    selectOnFocus,
    clearOnBlur,
    virtualized,
    classes,
    shortColumn,
    defaultValue,
}) => {
    let value = input.value;
    if (!input.value) {
        if (multiple) {
            if (defaultValue) {
                value = _.isArray(defaultValue) ? defaultValue : [defaultValue];
            } else {
                value = [];
            }
        } else {
            if (defaultValue) {
                value = _.isArray(defaultValue) ? defaultValue[0] : defaultValue;
            } else {
                value = "";
            }
        }
    }

    const autocomplete = (
        <MUIAutoComplete
            variant="standard"
            clearOnBlur={clearOnBlur}
            selectOnFocus={selectOnFocus}
            filterOptions={createFilterEachWordFromStart({
                matchFrom: "eachWordStart",
            })}
            freeSolo={freeSolo}
            multiple={multiple}
            options={items}
            ListboxComponent={virtualized ? VirtualizedListbox : undefined}
            value={items && items.length >= 0 ? value : null}
            getOptionLabel={
                getOptionLabel ||
                ((option) => {
                    return option;
                })
            }
            disableCloseOnSelect={disableCloseOnSelect}
            classes={{
                popupIndicator: classes.text,
                clearIndicator: classes.text,
                inputRoot: classes.text,
            }}
            style={{
                minWidth: shortColumn ? "5vw" : undefined,
            }}
            onChange={
                onCustomChange
                    ? (event, newValue) => onCustomChange(event, newValue, input.onChange)
                    : (_, a) => {
                          input.onChange(a);
                      }
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    label={label}
                    InputLabelProps={{
                        classes: { root: classes.label },
                        style: {
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "100%",
                            color: "#fff",
                        },
                    }}
                />
            )}
        />
    );
    const tooltip =
        input.value !== undefined && input.value !== "" ? `${label} - ${getOptionLabel ? getOptionLabel(input.value) : input.value}` : label;
    return (
        <FormControl
            fullWidth={true}
            classes={{
                root: classes.inputRoot,
            }}
        >
            {shortColumn ? (
                <Tooltip
                    title={tooltip}
                    aria-label={tooltip}
                    placement="right-start"
                >
                    {autocomplete}
                </Tooltip>
            ) : (
                autocomplete
            )}
        </FormControl>
    );
};

const CellBody = ({ classes, column, filterFunction, persistentFilter, initialFilterValue }) => {
    const [val, setVal] = useState(null);
    if (!column.filtrable) return column.value;
    if (column.type === formatTypes.time && !!!persistentFilter) {
        return (
            <DatePicker
                slotProps={{
                    textField: {
                        variant: "standard",
                    },
                }}
                slots={{
                    openPickerIcon: OpenPickerWhiteIcon,
                }}
                label={column.value}
                title={column.value}
                value={val ? (moment.isMoment(val) ? val : moment(val)) : null}
                defaultChecked={false}
                format={timePrecisonFormats.days}
                type={"text"}
                sx={inputRootStyle}
                classes={{
                    root: classes.inputRoot,
                }}
                InputProps={{
                    className: classes.text,
                }}
                InputLabelProps={{
                    style: {
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        color: "#fff",
                    },
                }}
                onChange={(event) => {
                    const value = event ? event.format() : event;
                    if (value && value !== "Invalid date") {
                        setVal(value);
                        filterFunction(column.key, value);
                    } else {
                        setVal(null);
                        filterFunction(column.key, undefined);
                    }
                }}
                ampm="false"
            />
        );
    }

    let filter = undefined;
    switch (column.filterType) {
        case "select":
            let items = (column.filterItems || []).map((x, index) => {
                const name = x.polishName || x.englishName || x.name;
                return (
                    <MenuItem
                        disabled={x.disabled}
                        key={index}
                        value={x.value}
                    >
                        <ListItemText>{name}</ListItemText>
                    </MenuItem>
                );
            });
            const findName = (value) => {
                const find = (val) =>
                    (column.filterItems.find((x) => (_.isArray(x.value) ? x.value.find((y) => y === val) : x.value === val)) || { name: "" }).name;

                if (!_.isArray(value)) return find(value);

                return value.map((v) => find(v)).join(", ");
            };

            filter = (
                <Select
                    variant="standard"
                    defaultValue={initialFilterValue ? initialFilterValue[column.filterName || column.key] : null}
                    label={column.value}
                    title={column.value}
                    type={"text"}
                    fullWidth
                    onChange={(event) => filterFunction(column.filterName || column.key, event.target.value)}
                    renderValue={(value) => findName(value) || value}
                >
                    {items}
                </Select>
            );
            break;
        case "autocomplete":
            filter = (
                <Autocomplete
                    options={column.filterItems || []}
                    defaultValue={initialFilterValue ? initialFilterValue[column.key] : null}
                    label={column.value}
                    title={column.value}
                    type={"text"}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                    }}
                    InputProps={{
                        className: classes.text,
                    }}
                    InputLabelProps={{
                        style: {
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "100%",
                            color: "#fff",
                        },
                    }}
                    getOptionLabel={column.filterGetOptionLabel}
                    onChange={(val) => filterFunction(column.filterName || column.key, val)}
                />
            );
            break;
        default:
            filter = (
                <TextField
                    variant="standard"
                    defaultValue={initialFilterValue ? initialFilterValue[column.key] : null}
                    label={column.value}
                    title={column.value}
                    type={"text"}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                    }}
                    InputProps={{
                        className: classes.text,
                    }}
                    InputLabelProps={{
                        style: {
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "100%",
                            color: "#fff",
                        },
                    }}
                    onChange={(event) => filterFunction(column.filterName || column.key, event.target.value)}
                />
            );
            break;
    }
    let filterForPersistent = undefined;
    switch (column.filterType) {
        case "autocomplete":
            filterForPersistent = renderAutocomplete;
            break;
        case "select":
            filterForPersistent = renderSelect;
            break;
        default:
            if (column.type === formatTypes.time) {
                filterForPersistent = renderDatePicker;
                break;
            }
            filterForPersistent = renderTextField;
            break;
    }

    return persistentFilter ? (
        <Field
            name={column.key}
            component={filterForPersistent}
            items={column.filterItems || []}
            options={column.filterItems || []}
            classes={classes}
            getOptionLabel={column.filterGetOptionLabel}
            onChange={(event) => filterFunction(column.filterName || column.key, event?.target ? event.target.value : event)}
            label={column.value}
            shortColumn={column.shortColumn}
            defaultValue={initialFilterValue ? initialFilterValue[column.filterName || column.key] : null}
        />
    ) : (
        filter
    );
};

const Head = ({
    columns,
    selectable,
    exportAll,
    allSelected,
    setAllSelected,
    filterFunction,
    handlePageChange,
    reset,
    submitting,
    persistentFilter,
    initialFilterValue,
    tableContainerRef,
    tableRef,
}) => {
    const { classes } = styles();
    const headRef = useRef();
    const ghostRef = useRef();
    const stickyHeadRef = useRef();
    const [isSticky, setIsSticky] = useState(false);
    const [headerCellsWidths, setHeaderCellsWidths] = useState([]);

    const resetFilter = () => {
        if (filterFunction !== undefined) {
            filterFunction(undefined);
            reset();
            setTimeout(() => handlePageChange(1), 100);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsSticky(entry.boundingClientRect.top < 0);
                const widths = headRef.current?.children[1]?.children
                    ? Array.from(headRef.current.children[1].children).map((child) => child.clientWidth)
                    : [];
                setHeaderCellsWidths(widths);
            },
            { threshold: 1 }
        );

        if (ghostRef.current) {
            observer.observe(ghostRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const syncScroll = () => {
            if (stickyHeadRef.current && tableContainerRef.current) {
                stickyHeadRef.current.scrollLeft = tableContainerRef.current.scrollLeft;
            }
        };
        const syncWidth = () => {
            if (stickyHeadRef.current && tableContainerRef.current) {
                stickyHeadRef.current.style.width = `${tableContainerRef.current.clientWidth}px`;
                const widths = headRef.current?.children[1]?.children
                    ? Array.from(headRef.current.children[1].children).map((child) => child.clientWidth)
                    : [];
                setHeaderCellsWidths(widths);
            }
        };

        if (tableContainerRef.current) {
            tableContainerRef.current.addEventListener("scroll", syncScroll);
        }
        const resizeObserver = new ResizeObserver(syncWidth);
        if (tableContainerRef.current) {
            resizeObserver.observe(tableContainerRef.current);
        }

        return () => {
            if (tableContainerRef.current) {
                tableContainerRef.current.removeEventListener("scroll", syncScroll);
            }
            resizeObserver.disconnect();
        };
    }, [tableContainerRef]);

    useEffect(() => {
        if (stickyHeadRef.current && tableContainerRef.current) {
            stickyHeadRef.current.scrollLeft = tableContainerRef.current.scrollLeft;
        }
    }, [isSticky]);

    return (
        <>
            <TableHead ref={headRef}>
                <TableRow
                    ref={ghostRef}
                    style={{ display: "flex", position: "sticky", top: 1, left: 0, width: 1, height: 1 }}
                />
                <TableRow className={classes.row}>
                    {selectable &&
                        (exportAll ? (
                            <Cell
                                key={"checkboxHeader"}
                                isHeader
                                className={classnames(classes.tableCell, classes.tableHeadCell, classes.cell, classes.borderTopLeft)}
                            >
                                <Checkbox
                                    checked={allSelected}
                                    onChange={() => setAllSelected(!allSelected)}
                                />
                            </Cell>
                        ) : (
                            <Cell
                                isHeader
                                className={classnames(classes.tableCell, classes.tableHeadCell, classes.cell, classes.borderTopLeft)}
                            />
                        ))}
                    {columns
                        .filter((x) => !x.hidden)
                        .map((column, i) => {
                            return (
                                <Cell
                                    isHeader
                                    format={column.type}
                                    colSpan={column.colSpan}
                                    key={`${column.key}-${i}`}
                                    className={classnames(
                                        classes.tableCell,
                                        classes.tableHeadCell,
                                        classes.cell,
                                        {
                                            [classes.borderTopLeft]: i === 0 && !selectable,
                                        },
                                        {
                                            [classes.borderTopRight]: i + 1 === columns.length,
                                        }
                                    )}
                                >
                                    {column.resetButton ? (
                                        <IconButton
                                            className={classes.text}
                                            tabIndex={0}
                                            onClick={!submitting ? () => resetFilter() : undefined}
                                        >
                                            {submitting === true ? (
                                                <CircularProgress
                                                    className={classes.progress}
                                                    size={15}
                                                />
                                            ) : (
                                                <ClearIcon className={classnames(styles.leftIcon, styles.iconSmall)} />
                                            )}
                                        </IconButton>
                                    ) : column.filtrable ? (
                                        <CellBody
                                            classes={classes}
                                            column={column}
                                            persistentFilter={persistentFilter}
                                            filterFunction={filterFunction}
                                            initialFilterValue={initialFilterValue}
                                        />
                                    ) : (
                                        column.value
                                    )}
                                </Cell>
                            );
                        })}
                </TableRow>
            </TableHead>
            {isSticky && (
                <TableHead
                    ref={stickyHeadRef}
                    sx={{
                        position: "fixed",
                        top: 0,
                        zIndex: 10,
                        width: tableContainerRef.current?.clientWidth || "100%",
                        overflow: "hidden",
                        backgroundColor: "white",
                    }}
                >
                    <TableRow sx={{ display: "flex", width: tableRef.current?.clientWidth || "100%" }}>
                        {selectable &&
                            (exportAll ? (
                                <Cell
                                    key={"checkboxHeader"}
                                    isHeader
                                    className={classnames(classes.tableCell, classes.tableHeadCell, classes.cell, classes.borderTopLeft)}
                                >
                                    <Checkbox
                                        checked={allSelected}
                                        onChange={() => setAllSelected(!allSelected)}
                                    />
                                </Cell>
                            ) : (
                                <Cell
                                    isHeader
                                    className={classnames(classes.tableCell, classes.tableHeadCell, classes.cell, classes.borderTopLeft)}
                                />
                            ))}
                        {columns
                            .filter((x) => !x.hidden)
                            .map((column, i) => {
                                return (
                                    <Cell
                                        isHeader
                                        format={column.type}
                                        colSpan={column.colSpan}
                                        key={`${column.key}-${i}`}
                                        className={classnames(
                                            classes.cell,
                                            {
                                                [classes.borderTopLeft]: i === 0 && !selectable,
                                            },
                                            {
                                                [classes.borderTopRight]: i + 1 === columns.length,
                                            }
                                        )}
                                        style={{
                                            boxSizing: "border-box",
                                            minWidth: headerCellsWidths[i] || 0,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {column.resetButton ? (
                                            <IconButton
                                                className={classes.text}
                                                tabIndex={0}
                                                onClick={!submitting ? () => resetFilter() : undefined}
                                            >
                                                {submitting === true ? (
                                                    <CircularProgress
                                                        className={classes.progress}
                                                        size={15}
                                                    />
                                                ) : (
                                                    <ClearIcon className={classnames(styles.leftIcon, styles.iconSmall)} />
                                                )}
                                            </IconButton>
                                        ) : column.filtrable ? (
                                            <CellBody
                                                classes={classes}
                                                column={column}
                                                persistentFilter={persistentFilter}
                                                filterFunction={filterFunction}
                                                initialFilterValue={initialFilterValue}
                                            />
                                        ) : (
                                            column.value
                                        )}
                                    </Cell>
                                );
                            })}
                    </TableRow>
                </TableHead>
            )}
        </>
    );
};

export default connect((state) => ({
    formState: state.form.SimpleTable,
}))(
    reduxForm({
        form: "SimpleTable",
        enableReinitialize: true,
    })(Head)
);
