import { makeStyles } from "tss-react/mui";
import { Button, Grid, TableBody as MuiTableBody, TableCell as MuiTableCell, TableRow as MuiTableRow, Theme } from "@mui/material";
import { SpinningPreloader } from "components-ts/preloaders";
import TableCell from "./TableCell";
import { TableItem, TableProps } from "./TableTypes";

const useStyles = makeStyles()((theme: Theme) => ({
    body: {
        "& tr:nth-of-type(2n)": {
            background: theme.palette.grey[100],
        },
    },
}));

type BodyProps<T extends TableItem> = Pick<TableProps<T>, "items" | "columns" | "rowHeight" | "loadable" | "handlers">;

const BodyItems = <T extends TableItem>({
    items,
    columns,
    rowHeight,
    handlers,
}: Pick<BodyProps<T>, "items" | "columns" | "rowHeight" | "handlers">) => {
    return (
        <>
            {items?.map((item, rowIndex) => (
                <MuiTableRow
                    hover
                    key={rowIndex}
                    onDoubleClick={() => {
                        if (handlers?.onRowDoubleClick) {
                            handlers.onRowDoubleClick(item);
                        }
                    }}
                >
                    {columns
                        .filter((c) => c.hideColumn !== true)
                        .map((column, index) => (
                            <TableCell
                                key={index}
                                column={column}
                                item={item}
                                rowHeight={rowHeight || 40}
                                rowIndex={rowIndex}
                            />
                        ))}
                </MuiTableRow>
            ))}
        </>
    );
};

const ErrorBody = <T extends TableItem>({ loadable, columns }: Required<Pick<BodyProps<T>, "loadable" | "columns">>) => {
    return (
        <MuiTableRow>
            <MuiTableCell
                rowSpan={25}
                colSpan={columns.length}
            >
                <Grid
                    style={{ height: 25 * 40 }}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>{"Coś poszło nie tak :("}</Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                loadable.refetch();
                            }}
                        >
                            Spróbuj przeładować
                        </Button>
                    </Grid>
                </Grid>
            </MuiTableCell>
        </MuiTableRow>
    );
};

const LoadingBody = <T extends TableItem>({ columns }: Required<Pick<BodyProps<T>, "columns">>) => {
    return (
        <MuiTableRow>
            <MuiTableCell
                rowSpan={25}
                colSpan={columns.length}
            >
                <Grid
                    style={{ height: "50%", position: "relative" }}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <SpinningPreloader />
                </Grid>
            </MuiTableCell>
        </MuiTableRow>
    );
};

const BodySwitch = <T extends TableItem>({ loadable, ...rest }: BodyProps<T>) => {
    if (!loadable) {
        return <BodyItems {...rest} />;
    }

    if (loadable.isRefetching) {
        return <LoadingBody columns={rest.columns} />;
    }

    switch (loadable.status) {
        case "error":
            return (
                <ErrorBody
                    loadable={loadable}
                    columns={rest.columns}
                />
            );
        case "pending":
            return <LoadingBody columns={rest.columns} />;
        case "success":
            return <BodyItems {...rest} />;
    }
};

const TableBody = <T extends TableItem>(props: BodyProps<T>): JSX.Element => {
    const { classes } = useStyles();

    return (
        <MuiTableBody className={classes.body}>
            <BodySwitch {...props} />
        </MuiTableBody>
    );
};

export default TableBody;
