import { useFileQuery } from "api/hooks";

export const useGetOrderDocument = (orderId: string, documentId: string, documentNumber: string) => {
    const [customerId, orderNumber] = orderId.split("/");

    return useFileQuery({
        app: "operations",
        url: `api/users/${customerId}/orders/${orderNumber}/get-order-document/${documentId}`,
        shouldMakeRequest: Boolean(documentId) && Boolean(orderId),
        fileName: documentNumber?.replace("/", "_"),
    });
};
