import { IconButton } from "components/Button";
import { FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { FormSection } from "redux-form";
import { actions } from "store/cache/products/actions";
import { useCommonTranslation, useFormValueSelector, useMappedDispatch } from "utils/hooks";
import Validator, { beInteger, decimalPlaces, greaterThan, mustBeNumber, notEmpty } from "utils/validators/basic";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { tValidation, validation } from "utils-ts/validations/translation";
import { validators } from "./EffectForm/validators";
import { productsSelector } from "./PromotionPredicateFieldsComponents/selectors";
import PromotionProductsFields from "./PromotionProductsFields";
import { promotionTypes } from "./promotionTypes";

const optionalValidators = {
    beDecimalWhenNotEmpty: (value) => {
        value = value ? value : "";

        return new Validator(value)
            .must(mustBeNumber, tValidation(validation.mustBeNumber))
            .must(decimalPlaces([0, 2]), tValidation(validation.decimalPlaces))
            .must(greaterThan(0), tValidation(validation.greaterThanValidation, { number: 0 }))
            .allWhen(notEmpty(value))
            .validate();
    },
    beIntegerWhenNotEmpty: (value) => {
        value = value ? value : "";

        return new Validator(value)
            .must(beInteger, tValidation(validation.mustBeInteger))
            .must(greaterThan(0), tValidation(validation.greaterThanValidation, { number: 0 }))
            .allWhen(notEmpty(value))
            .validate();
    },
    mustBeNumberWhenNotEmpty: (value) => {
        value = value ? value : "";

        return new Validator(value).must(mustBeNumber, tValidation(validation.mustBeNumber)).when(notEmpty(value)).validate();
    },
};

const PromotionPoolPredicateFields = ({
    prefix = "",
    formName,
    renderLayout = true,
    renderRemove = false,
    onRemove,
    renderRequiremtnts = true,
    required = true,
    readOnly = false,
    promotionType = undefined,
    showExportPoolProducts,
    showPromotionExistAlert,
}) => {
    const { t, common } = useCommonTranslation();
    const { getProducts } = useMappedDispatch(actions);
    const fieldNames = prefix ? prefix : ["includeProducts", "excludeProducts"];
    const productsFormState = useFormValueSelector(formName, fieldNames);
    const { includeProducts, excludeProducts } = productsFormState;
    const products = useSelector(productsSelector) || [];

    useEffect(() => {
        const { products: iProducts } = includeProducts || {};
        const { products: eProducts } = excludeProducts || {};
        const mergedProducts = [...(iProducts || []), ...(eProducts || [])];

        const filteredProducts = mergedProducts?.filter((mergedProduct) => !products.some((product) => mergedProduct.id === product.id)) || [];

        if (filteredProducts?.length > 0) {
            getProducts(filteredProducts);
        }
    }, [includeProducts, excludeProducts, products, getProducts]);

    const requiredQuantityValue = [promotionTypes.PackGratis, promotionTypes.Pack, promotionTypes.PoolGratis, promotionTypes.Pool].includes(
        promotionType
    );

    const requiredEitherQuantityOrValue = [promotionTypes.Discount, promotionTypes.Pair, promotionTypes.Sampling].includes(promotionType);
    const selectedIncludeProducts = includeProducts?.products;
    const selectedExcludeProducts = excludeProducts?.products;

    const component = (
        <FormSection name={prefix}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                {renderRemove && (
                    <Grid item>
                        <IconButton
                            icon={"delete"}
                            onClick={onRemove}
                        />
                    </Grid>
                )}
                {renderRequiremtnts ? (
                    <Fragment>
                        <FormField
                            name={"requiredQuantity"}
                            label={t(common.requiredQuantity)}
                            type={"number"}
                            validate={[
                                required
                                    ? requiredQuantityValue
                                        ? validators.quantity
                                        : requiredEitherQuantityOrValue
                                        ? validators.oneIsRequired
                                        : validators.atLeastOneRequirementIsRequired
                                    : undefined,
                                optionalValidators.beIntegerWhenNotEmpty,
                            ]}
                        />
                        <FormField
                            name={"requiredValue"}
                            label={t(common.requiredValue)}
                            type={"number"}
                            validate={[
                                required
                                    ? requiredEitherQuantityOrValue
                                        ? validators.oneIsRequired
                                        : validators.atLeastOneRequirementIsRequired
                                    : undefined,
                                optionalValidators.beDecimalWhenNotEmpty,
                            ]}
                        />
                    </Fragment>
                ) : (
                    <Fragment />
                )}
                <PromotionProductsFields
                    name="includeProducts"
                    required={required}
                    title={t(common.includeProducts)}
                    readOnly={readOnly}
                    exportProducts={showExportPoolProducts}
                    selectedProducts={selectedIncludeProducts}
                    showPromotionExistAlert={showPromotionExistAlert}
                />
                <PromotionProductsFields
                    name="excludeProducts"
                    title={t(common.excludeProducts)}
                    readOnly={readOnly}
                    exportProducts={showExportPoolProducts}
                    selectedProducts={selectedExcludeProducts}
                />
            </Grid>
        </FormSection>
    );

    if (renderLayout) {
        return (
            <Layout
                isEnlarged
                headerText={t(common.pool)}
            >
                {component}
            </Layout>
        );
    }

    return component;
};

export default PromotionPoolPredicateFields;
