import { makeStyles } from "tss-react/mui";
import { Key, RefObject, useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { IconButton, TableCell as MuiTableCell, TableHead as MuiTableHead, TableRow as MuiTableRow, Theme } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { TableItem, TableProps } from ".";
import { TranslationFunction } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { NestedKeyOf } from "Shared";
import { Autocomplete, Checkbox, DatePicker, DateTimePicker, Select, TextField } from "components-ts/controls";
import { ActionColumn, Column, TableHandlers, ValueColumn } from "./TableTypes";
import useTableStyles from "./tableCommonStyles";

const useHeaderStyles = makeStyles()((theme: Theme) => ({
    headerCell: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        wordBreak: "break-word",
    },
    stickyHeaderCell: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
    },
    borderTopLeft: {
        borderRadius: "7px 0px 0px 0px",
    },
    borderTopRight: {
        borderRadius: "0px 7px 0px 0px",
    },
    label: {
        color: theme.palette.primary.contrastText,
    },
    inputRoot: {
        "width": "100%",
        "color": theme.palette.primary.contrastText,
        "input": {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
        },
        "&.Mui-focused": {
            color: theme.palette.primary.contrastText,
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& label": {
            color: theme.palette.primary.contrastText,
        },
        "& label.Mui-focused": {
            color: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:hover:not($disabled):not($error)": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiAutocomplete-clearIndicator": {
            color: theme.palette.primary.contrastText,
        },
        "& .MuiAutocomplete-popupIndicator": {
            color: theme.palette.primary.contrastText,
        },
        ":before": {
            borderColor: theme.palette.primary.contrastText,
        },
        ":after": {
            borderColor: theme.palette.primary.contrastText,
        },
    },
}));

const OpenPickerWhiteIcon = styled(EventIcon)({
    color: "#fff",
});

const ClearWhiteIcon = styled(ClearIcon)({
    color: "#fff",
});

const isAction = <T extends TableItem>(column: ActionColumn<T> | ValueColumn<T>): column is ActionColumn<T> => {
    return (column as ActionColumn<T>).actionType !== undefined;
};

const FiltrableHead = <T extends TableItem>({
    column,
    filter,
    setFilter,
    t,
    onFilterChange,
    classes,
    size,
}: {
    column: ValueColumn<T>;
    filter: T;
    setFilter: (filter: T) => void;
    t: TranslationFunction;
    onFilterChange?: (name: NestedKeyOf<T>, value?: unknown) => void;
    classes: Record<"headerCell" | "borderTopLeft" | "borderTopRight" | "inputRoot" | "label", string>;
    size?: "small" | "medium";
}) => {
    const clearFilterValue = () => {
        setFilter({
            ...filter,
            [column.property.toString()]: undefined,
        });

        if (onFilterChange && !isAction(column)) {
            onFilterChange(column.property, undefined);
        }
    };
    const clearIconAdornment = (
        <IconButton onClick={clearFilterValue}>
            <ClearIcon className={classes.headerCell} />
        </IconButton>
    );

    const propertyName = column.property.toString();
    const label = column.label ? t(column.label).toString() : "";
    const value = filter[propertyName];
    const filterAs = column?.filterAs ?? column?.as;

    switch (filterAs) {
        case "select":
            return (
                <Select
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                        icon: classes.label,
                    }}
                    items={column.filterItems || []}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    size={size}
                />
            );

        case "autocomplete":
            return (
                <Autocomplete
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    items={column.filterItems || []}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                />
            );

        case "date":
            return (
                <DatePicker
                    label={label}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    slots={{
                        openPickerIcon: OpenPickerWhiteIcon,
                        clearIcon: ClearWhiteIcon,
                    }}
                />
            );

        case "dateTime":
            return (
                <DateTimePicker
                    label={label}
                    value={moment.isMoment(value) ? value : value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    onChange={(value) => {
                        setFilter({
                            ...filter,
                            [propertyName]: value,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, value);
                        }
                    }}
                    slots={{
                        openPickerIcon: OpenPickerWhiteIcon,
                        clearIcon: ClearWhiteIcon,
                    }}
                />
            );

        case "boolean":
            return (
                <Checkbox
                    label={label}
                    value={value === undefined ? false : Boolean(value)}
                    indeterminate={value === undefined ? undefined : value === false}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    labelPlacement="top"
                    labelClasses={{
                        root: classes.label,
                    }}
                    onChange={(value) => {
                        let newValue = value;
                        if (column.transformFilterValue) {
                            newValue = column.transformFilterValue(value) as boolean | undefined;
                        }

                        setFilter({
                            ...filter,
                            [propertyName]: newValue,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newValue);
                        }
                    }}
                    size={size}
                    sx={{
                        "color": "#fff",
                        "&.Mui-checked": {
                            color: "#fff",
                        },
                        "&.MuiCheckbox-indeterminate": {
                            color: "#fff",
                        },
                    }}
                />
            );

        case "decimal":
        case "integer":
            return (
                <TextField
                    numberType={column.as === "decimal" ? "decimal" : "numeric"}
                    label={label}
                    onChange={(newValue) => {
                        const newNumber =
                            column.as === "decimal" ? Number.parseFloat(newValue?.replace(",", ".")) : Number.parseInt(newValue?.replace(",", "."));
                        if (newNumber === 0 && newValue !== "0") {
                            clearFilterValue();
                            return;
                        }

                        if (isNaN(newNumber)) {
                            return;
                        } else if (column.as === "decimal" && !Number.isFinite(newNumber)) {
                            return;
                        } else if (column.as === "integer" && !Number.isInteger(newNumber)) {
                            return;
                        }

                        setFilter({
                            ...filter,
                            [propertyName]: newNumber,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newNumber);
                        }
                    }}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    adornment={{ position: "end", value: clearIconAdornment }}
                />
            );

        case "string":
        case undefined:
        default:
            return (
                <TextField
                    label={label}
                    onChange={(newValue) => {
                        setFilter({
                            ...filter,
                            [propertyName]: newValue,
                        });

                        if (onFilterChange && !isAction(column)) {
                            onFilterChange(column.property, newValue);
                        }
                    }}
                    value={value?.toString()}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    adornment={{ position: "end", value: clearIconAdornment }}
                />
            );
    }
};

const HeaderRow = <T extends TableItem>({
    columns,
    filter,
    setFilter,
    isSelectedAll,
    handlers,
    actionCells,
    isSticky,
    headerCellsWidths,
    styles,
}: {
    columns: Column<T>[];
    filter: T;
    setFilter: React.Dispatch<React.SetStateAction<T>>;
    isSelectedAll: boolean;
    handlers: TableHandlers<T> | undefined;
    actionCells: ActionColumn<T>[];
    isSticky: boolean;
    headerCellsWidths?: number[];
    styles?: {};
}) => {
    const { t } = useTranslation();
    const { classes } = useTableStyles();
    const { classes: headerClasses, cx } = useHeaderStyles();

    return (
        <MuiTableRow sx={styles}>
            {columns
                .filter((c) => c.hideColumn !== true)
                .map((c, i) => {
                    const { label } = c;
                    const valueColumn = c as ValueColumn<T>;
                    const actionColumn = isAction(c) ? c : undefined;
                    const isLastActionCell = actionColumn !== undefined && actionCells.at(-1)?.actionType === actionColumn.actionType;
                    const cellTypeStyle =
                        valueColumn.as === "date"
                            ? classes.dateCell
                            : valueColumn.as === "boolean"
                            ? classes.booleanCell
                            : valueColumn.as === "decimal" || valueColumn.as === "integer" || valueColumn.as === "percent"
                            ? classes.numericCell
                            : undefined;

                    return (
                        <MuiTableCell
                            className={cx(
                                headerClasses.headerCell,
                                i === 0 ? headerClasses.borderTopLeft : i === columns.length - 1 ? headerClasses.borderTopRight : undefined,
                                isSticky ? headerClasses.stickyHeaderCell : cellTypeStyle
                            )}
                            sx={
                                headerCellsWidths && isSticky
                                    ? {
                                          width: headerCellsWidths[i] || 0,
                                          justifyContent: valueColumn.as === "boolean" ? "center" : "left",
                                          textAlign: valueColumn.as === "boolean" ? "center" : "left",
                                      }
                                    : {}
                            }
                            key={`${!isAction(c) ? c.property : c.actionType}-${i}` as Key}
                            {...c.cellProps}
                        >
                            {valueColumn !== undefined && valueColumn.filtrable ? (
                                <FiltrableHead
                                    column={valueColumn}
                                    filter={filter}
                                    setFilter={setFilter}
                                    onFilterChange={handlers?.onFilterChange}
                                    classes={headerClasses}
                                    t={t}
                                    size={c.cellProps?.size}
                                />
                            ) : actionColumn !== undefined && actionColumn.actionType === "select" ? (
                                <Checkbox
                                    label=""
                                    value={isSelectedAll}
                                    onChange={(newValue) => {
                                        if (handlers?.onSelectAllChange) {
                                            handlers.onSelectAllChange(newValue);
                                        }
                                    }}
                                    classes={{
                                        root: headerClasses.inputRoot,
                                    }}
                                    sx={{
                                        "color": "#fff",
                                        "&.Mui-checked": {
                                            color: "#fff",
                                        },
                                        "&.MuiCheckbox-indeterminate": {
                                            color: "#fff",
                                        },
                                    }}
                                />
                            ) : label ? (
                                t(label).toString()
                            ) : isLastActionCell && handlers?.onFilterClear && columns.some((c) => "filtrable" in c && c.filtrable) ? (
                                <IconButton
                                    onClick={() => {
                                        setFilter({} as T);
                                        if (handlers?.onFilterClear !== undefined) {
                                            handlers.onFilterClear();
                                        }
                                    }}
                                >
                                    <ClearIcon className={headerClasses.headerCell} />
                                </IconButton>
                            ) : undefined}
                        </MuiTableCell>
                    );
                })}
        </MuiTableRow>
    );
};

const TableHead = <T extends TableItem>({
    columns,
    handlers,
    initialFilters,
    tableContainerRef,
    tableRef,
    isSelectedAll,
}: Pick<TableProps<T>, "columns" | "handlers" | "initialFilters"> & {
    tableContainerRef: RefObject<HTMLDivElement>;
    tableRef: RefObject<HTMLTableElement>;
    isSelectedAll: boolean;
}): JSX.Element => {
    const [filter, setFilter] = useState<T>((initialFilters || {}) as T);
    const actionCells = columns.filter(isAction) || [];
    const headRef = useRef<HTMLTableSectionElement>(null);
    const ghostRef = useRef<HTMLTableRowElement>(null);
    const stickyHeadRef = useRef<HTMLTableSectionElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    const [headerCellsWidths, setHeaderCellsWidths] = useState<number[]>([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsSticky(entry.boundingClientRect.top < 0);
                const widths = headRef.current?.children[1]?.children
                    ? Array.from(headRef.current.children[1].children).map((child) => child.clientWidth)
                    : [];
                setHeaderCellsWidths(widths);
            },
            { threshold: 1 }
        );

        if (ghostRef.current) {
            observer.observe(ghostRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const syncScroll = () => {
            if (stickyHeadRef.current && tableContainerRef.current) {
                stickyHeadRef.current.scrollLeft = tableContainerRef.current.scrollLeft;
            }
        };
        const syncWidth = () => {
            if (stickyHeadRef.current && tableContainerRef.current) {
                stickyHeadRef.current.style.width = `${tableContainerRef.current.clientWidth}px`;
                const widths = headRef.current?.children[1]?.children
                    ? Array.from(headRef.current.children[1].children).map((child) => child.clientWidth)
                    : [];
                setHeaderCellsWidths(widths);
            }
        };

        if (tableContainerRef.current) {
            tableContainerRef.current.addEventListener("scroll", syncScroll);
        }
        const resizeObserver = new ResizeObserver(syncWidth);
        if (tableContainerRef.current) {
            resizeObserver.observe(tableContainerRef.current);
        }

        return () => {
            if (tableContainerRef.current) {
                tableContainerRef.current.removeEventListener("scroll", syncScroll);
            }
            resizeObserver.disconnect();
        };
    }, [tableContainerRef]);

    useEffect(() => {
        if (stickyHeadRef.current && tableContainerRef.current) {
            stickyHeadRef.current.scrollLeft = tableContainerRef.current.scrollLeft;
        }
    }, [isSticky]);

    return (
        <>
            <MuiTableHead ref={headRef}>
                <MuiTableRow
                    ref={ghostRef}
                    sx={{ display: "flex", position: "sticky", top: 1, left: 0, width: 1, height: 1 }}
                />
                <HeaderRow
                    columns={columns}
                    filter={filter}
                    setFilter={setFilter}
                    isSelectedAll={isSelectedAll}
                    handlers={handlers}
                    actionCells={actionCells}
                    isSticky={false}
                />
            </MuiTableHead>
            {isSticky && (
                <MuiTableHead
                    ref={stickyHeadRef}
                    sx={{
                        position: "fixed",
                        top: 0,
                        zIndex: 10,
                        width: tableContainerRef.current?.clientWidth || "100%",
                        overflow: "hidden",
                        backgroundColor: "white",
                    }}
                >
                    <HeaderRow
                        columns={columns}
                        filter={filter}
                        setFilter={setFilter}
                        isSelectedAll={isSelectedAll}
                        handlers={handlers}
                        actionCells={actionCells}
                        isSticky={true}
                        headerCellsWidths={headerCellsWidths}
                        styles={{ display: "flex", width: tableRef.current?.clientWidth || "100%" }}
                    />
                </MuiTableHead>
            )}
        </>
    );
};

export default TableHead;
