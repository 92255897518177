import { Button } from "components/Button";
import Preloader from "components/Preloader/Preloader";
import { withStyles } from "tss-react/mui";
import formatValue from "utils/formating/formatValue";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import Cell from "./Cell";
import Head from "./Head";
import Row from "./Row";
import Action from "./TableActions/Action";
import TablePagination from "./TablePagination";
import classnames from "classnames";

export const createColumn = (key, value, type, options, action, filtrable, disabled) => {
    return {
        key,
        value,
        type,
        options,
        action,
        actions: options?.actions,
        actionType: options?.actionType,
        filtrable,
        translate: options?.translate,
        disabled,
        filterType: options?.filterType,
        filterItems: options?.filterItems,
        resetButton: options?.resetButton,
        transform: options?.transform,
    };
};

const styles = (theme) => ({
    root: {
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
    },
    stripped: {
        "& tr:nth-of-type(even)": {
            backgroundColor: "#f9f9f9",
        },
    },
    emptyRow: {
        backgroundColor: "white !important",
    },
});
const getProp = (property, obj) => property.split(".").reduce((t, p) => t?.[p], obj);
const renderCell = ({ key, type, colSpan, action, disabled, options, translate, transform, ...actionProps }, columnIndex, item, itemIndex, t) => {
    const val = transform ? transform(getProp(key, item)) : getProp(key, item);
    return type !== "action" ? (
        <Cell
            className={item.className ? item.className : options?.className}
            key={`${key}-${itemIndex}-${columnIndex}`}
            colSpan={colSpan}
            format={type}
            color={actionProps.color}
            tooltip={actionProps.tooltip}
            showZero={options?.showZero}
            value={formatValue(translate ? t(val) : val, type, options, item)}
        />
    ) : (
        <Action
            disabled={disabled && disabled(item)}
            key={`${key}-${itemIndex}-${columnIndex}`}
            colSpan={colSpan}
            handleClick={() => action(item)}
            buttonText={options?.buttonText}
            item={item}
            {...actionProps}
            isLoading={options?.isLoading && options.isLoading(item)}
            actionType={typeof actionProps?.actionType === "function" ? actionProps.actionType(item) : actionProps?.actionType}
        />
    );
};

const renderDefaultRows = (
    columns,
    data,
    onRowDoubleClick,
    hover,
    selectable,
    handleCheckBoxClicked,
    isItemSelected,
    allSelected,
    t,
    selectCondition,
    highlightedRow
) => {
    return (data ?? [])
        .filter((x) => !x.hidden)
        .map((item, itemIndex) => (
            <Row
                hover={hover}
                key={itemIndex}
                onDobuleClick={(event) => (onRowDoubleClick ? onRowDoubleClick(event, item) : null)}
                highlighted={highlightedRow && highlightedRow === item.baseId}
            >
                <Fragment>
                    {selectable && (
                        <Cell key={"checkbox"}>
                            <Checkbox
                                checked={isItemSelected(itemIndex, allSelected, Boolean(selectCondition && !selectCondition(item)))}
                                onChange={() => handleCheckBoxClicked(itemIndex, item)}
                                disabled={Boolean(selectCondition && !selectCondition(item))}
                            />
                        </Cell>
                    )}
                    {columns.filter((x) => !x.hidden).map((column, columnIndex) => renderCell(column, columnIndex, item, itemIndex, t))}
                </Fragment>
            </Row>
        ));
};

const renderLoading = (length, rowsPerPage) => {
    return (
        <Row>
            <Cell colSpan={length}>
                <Preloader height={48 * rowsPerPage} />
            </Cell>
        </Row>
    );
};

const renderPagination = (paginationProps) => <TablePagination paginationProps={paginationProps} />;

const renderExportButton = (
    isExportLoading,
    exportAll,
    selected,
    allSelected,
    onSelectedExport,
    buttonExportText,
    fullWidth,
    setSelected,
    unselectAllAfterExport
) => (
    <Button
        loading={isExportLoading}
        variant="contained"
        color="primary"
        disabled={!exportAll ? selected?.length === 0 : !allSelected && selected?.length === 0}
        onClick={() => {
            onSelectedExport(
                selected.map((x) => x.item),
                allSelected
            );
            if (unselectAllAfterExport) {
                setSelected([]);
            }
        }}
        fullWidth={fullWidth}
    >
        {buttonExportText}
    </Button>
);

const SimpleTable = ({
    columns,
    children,
    classes,
    pagination,
    paginationProps = {},
    isLoading,
    data,
    onRowDoubleClick,
    stripped,
    hover,
    fillEmptyRows = true,
    tableActions = [],
    noHeader,
    selectable,
    exportAll,
    onSelectedExport,
    selectCondition,
    isExportLoading,
    className,
    size,
    filterFunction,
    initialFilterValue,
    t,
    persistentFilter,
    buttonExportText = "Export",
    buttonExportPosition = "top",
    unselectAllAfterExport = false,
    filterUndefinedColumns,
    highlightedRow = undefined,
}) => {
    const filteredColumns = filterUndefinedColumns ? columns.filter(Boolean) : columns;
    const [selected, setSelected] = React.useState([]);
    const [allSelected, setAllSelected] = React.useState(false);
    const [filterTimeout, setFilterTimeout] = React.useState(undefined);
    let body = children;
    let paginationComponent = undefined;
    let exportButton = undefined;
    const { pageIndex, pageSize = 10 } = paginationProps;
    const tableContainerRef = React.useRef();
    const tableRef = React.useRef();

    if (isLoading) body = renderLoading(filteredColumns.length, pageSize);
    else {
        if (!children) {
            const isItemSelected = (index, allSelected, isDisabled) => {
                const key = `${index}-${pageIndex || 0}-${pageSize || 0}`;
                return (!isDisabled && allSelected) || selected.some((x) => x.key === key);
            };

            const handleCheckBoxClicked = (index, item) => {
                let items = selected;
                const key = `${index}-${pageIndex || 0}-${pageSize || 0}`;
                if (items.some((x) => x.key === key)) {
                    items = items.filter((x) => x.key !== key);
                } else {
                    items = [...items, { key, item }];
                }
                setSelected(items);
            };

            body = renderDefaultRows(
                filteredColumns,
                data,
                onRowDoubleClick,
                hover,
                selectable,
                handleCheckBoxClicked,
                isItemSelected,
                allSelected,
                t,
                selectCondition,
                highlightedRow
            );
        }

        if (pagination) {
            paginationComponent = renderPagination(paginationProps);
            if (fillEmptyRows) {
                let emptyRowsCount = pageSize - body.length;
                if (emptyRowsCount < 0) {
                    emptyRowsCount = 0;
                }
                const emptyRows = React.Children.toArray(Array(emptyRowsCount).fill(<TableRow className={classes.emptyRow}></TableRow>));
                body = [...body, ...emptyRows];
            }
        }

        if (selectable) {
            exportButton = renderExportButton(
                isExportLoading,
                exportAll,
                selected,
                allSelected,
                onSelectedExport,
                buttonExportText,
                buttonExportPosition === "bottom",
                setSelected,
                unselectAllAfterExport
            );
        }
    }

    return (
        <div className={classnames(classes.root, className)}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                {buttonExportPosition === "top" && exportButton}
                {tableActions.map((x, i) => (
                    <Grid
                        item
                        key={i}
                    >
                        {x}
                    </Grid>
                ))}
            </Grid>
            <TableContainer ref={tableContainerRef}>
                <Table
                    className={classnames(classes.table, classes.tableResponsive, stripped ? classes.stripped : "")}
                    size={size || "medium"}
                    padding="none"
                    ref={tableRef}
                >
                    {noHeader ? (
                        <Fragment />
                    ) : (
                        <Head
                            columns={filteredColumns}
                            persistentFilter={persistentFilter}
                            allSelected={allSelected}
                            setAllSelected={setAllSelected}
                            exportAll={exportAll}
                            setSelected={setSelected}
                            selectable={selectable}
                            filterFunction={filterFunction}
                            handlePageChange={paginationProps.onPageChange}
                            filterTimeout={filterTimeout}
                            setFilterTimeout={setFilterTimeout}
                            initialFilterValue={initialFilterValue}
                            tableContainerRef={tableContainerRef}
                            tableRef={tableRef}
                        />
                    )}
                    <TableBody>{body}</TableBody>
                </Table>
            </TableContainer>
            {paginationComponent}
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                {buttonExportPosition === "bottom" && exportButton}
            </Grid>
        </div>
    );
};

export default withTranslation()(withStyles(SimpleTable, styles));

SimpleTable.propTypes = {
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    pagination: PropTypes.bool,
    filterFunction: PropTypes.func,
    initialFilterValue: PropTypes.object,
    paginationProps: PropTypes.shape({
        page: PropTypes.number,
        count: PropTypes.number,
        pageSize: PropTypes.number,
        onPageChange: PropTypes.func,
        onRowsPerPageChange: PropTypes.func,
    }),
};
